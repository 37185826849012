import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { defaultTheme } from './themes'
import LoggedIn from './components/LoggedIn'
import Lite from './pages/Lite'
import Login from './pages/Login'
import RequestPasswordReset from './pages/PasswordReset/Request'
import PasswordReset from './pages/PasswordReset'
import { useAuthStore } from './stores/auth'

export default function App() {
  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <Helmet>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1"
              />
              <title>Stitch</title>
              <meta charSet="utf-8" />
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap"
              />
            </Helmet>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/password_reset/request" element={<RequestPasswordReset />} />
              <Route path="/password_reset" element={<PasswordReset />} />
              <Route path="/lite" element={<ProtectedRoute><Lite /></ProtectedRoute>} />
              <Route path="/*" element={<ProtectedRoute><LoggedIn /></ProtectedRoute>} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </>
  )
}

const ProtectedRoute = ({ children, ...props }) => {
  const jwt = useAuthStore((state) => state.jwt)
  const rehydrated = useAuthStore((state) => state.rehydrated)
  const navigate = useNavigate()

  if (!rehydrated) return null
  if (!jwt) {
    navigate('/login')
  }

  return (children)
}