import { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Nav from './Nav'
import Header from './Header'
import {
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from '../pages/Dashboard'
import { SPACING } from '../constants'
import Memberships from '../pages/Memberships'
import MembershipDetail from '../pages/Memberships/MembershipDetail'
import Settings from '../pages/Settings'
import Employees from '../pages/Employees'
import Patrons from '../pages/Patrons'
import Help from '../pages/Help'
import PatronDetail from '../pages/Patrons/PatronDetail'
import NewMembership from '../pages/Memberships/NewMembership'

const App: FunctionComponent = () => {
  const styles = useStyles()

  return (
    <Box className={styles.mainContainer}>
      <Nav />
      <Box className={styles.bodyContainer}>
        <Header />
        <Box className={styles.innerContainer}>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/memberships/*'>
              <Route index element={<Memberships />} />
              <Route path='new' element={<NewMembership />} />
              <Route path={":id/*"}>
                <Route index element={<MembershipDetail />} />
              </Route>
            </Route>
            <Route path='/patrons/*'>
              <Route index element={<Patrons />} />
              <Route path={":id/*"} element={<PatronDetail />} />
            </Route>
            <Route path='/settings/*' element={<Settings />} />
            <Route path='/employees/*' element={<Employees />} />
            <Route path='/help' element={<Help />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    display: 'flex'
  },
  bodyContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  innerContainer: {
    padding: SPACING * 4,
    flex: 1,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    }
  }
}))

export default App;

