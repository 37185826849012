import { useState, useEffect, FunctionComponent } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { CircularProgress, InputLabel, Box, MenuItem, Select, FormControl, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import { SPACING } from '../../constants'
import Skeleton from 'react-loading-skeleton'
import axios from '../../utils/axios'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import queryString from 'query-string'

const LOGO_SIZE = 150

const MailChimpSettings: FunctionComponent = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [lists, setLists] = useState([])
  const [selectedListId, setSelectedListId] = useState('')

  useEffect(() => {
    (async () => {
      const { code } = queryString.parse(location.search)

      if (code) {
        await connectToMailChimp(String(code))
      }

      getMailChimpSttings()
    })()
  }, [location])

  const connectToMailChimp = async (code: string) => {
    try {
      const resp = await axios.post('/organization/connect_to_mailchimp', { code })
      return resp
    } catch (e) {
      console.log(e)
    }
  }

  const getMailChimpSttings = async () => {
    const resp = await axios.get('/organization/mailchimp_settings')
    setLists(resp.data.availalbleAudiences)
    setSelectedListId(resp.data.mailchimpListId)
    setLoading(false)
  }

  const handleListSelect = (event: any) => {
    setSelectedListId(event.target.value)
  }

  const submit = async () => {
    setSubmitting(true)
    const res = await axios.post('/organization/update_mailchimp_list_id', {
      list_id: selectedListId
    })

    if (res.status === 204) {
      navigate('/settings')
    }

    setSubmitting(false)
  }

  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Settings', linkPath: '/settings' },
        { title: 'Integrations', linkPath: '/settings' },
        { title: 'MailChimp', linkPath: '/settings/integrations/mailchimp' },
      ]} closePath={'/settings'}
      />
      <Box className={styles.bodyContainer}>
        <Box className={styles.formContainer}>
          <Box className={styles.banner}>
            <img src={'/mailchimp.png'} alt="MailChimp Logo" width={LOGO_SIZE} height={LOGO_SIZE} />
          </Box>
          <Box mb={2}>
            <Typography className={styles.formTitle}>
              MailChimp Integration Settings
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography>
              Select a MailChimp list to send your Stitch contacts to:
            </Typography>
          </Box>
          <Box>
            {loading
              ? <Skeleton height={48} />
              : <FormControl fullWidth variant="outlined">
                <InputLabel>Select Audience</InputLabel>
                <Select label="Select Auience" value={selectedListId} onChange={handleListSelect}>
                  {lists.map((list: { id: string, name: string }) => <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>)}
                </Select>
              </FormControl>
            }
          </Box>
          <Box mt={2} className={styles.buttonContainer}>
            <Box mr={1}>
              {loading
                ? <Skeleton height={40} width={120} />
                : <Link to="/settings">
                  <Button variant="text" color="secondary">
                    Cancel
                  </Button>
                </Link>
              }
            </Box>
            <Box className={styles.submitContainer}>
              {loading
                ? <Skeleton height={40} width={172} />
                : <Button disabled={!selectedListId || submitting} onClick={submit}>
                  Save Connection
                </Button>
              }
              {submitting ? <CircularProgress size={18} className={styles.progress} /> : ''}
            </Box>
          </Box>
        </Box>
      </Box>
    </DetailOverlayPage>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    borderTop: `8px solid ${colors.BRIGHT_BLUE}`,
    background: 'white',
    top: 0,
    left: 0,
  },
  formTitle: {
    fontWeight: 'bold'
  },
  formContainer: {
    marginTop: SPACING * 4,
    display: 'flex',
    width: 300,
    flexDirection: 'column',
  },
  banner: {
    width: '100%',
    borderRadius: 6,
    backgroundColor: '#FFE01B',
    marginBottom: SPACING * 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logosContainer: {
    display: 'flex',
    marginBottom: SPACING * 4,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9,
  },
  submitContainer: {
    position: 'relative'
  },
  bodyContainer: {
    display: 'flex',
    width: '100vw',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
export default MailChimpSettings
