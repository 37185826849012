import React, { FunctionComponent, useEffect, useState, PropsWithChildren, useCallback } from 'react'
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import SearchBar from '../../components/SearchBar'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import Fuse from 'fuse.js'
import { useNavigate } from 'react-router-dom'
import useEmployeeStore from '../../stores/employee'
import Pill from '../../components/Pill'
import { Employee, EmployeeSortableId } from '../../types'
import { ArrowDown, ArrowUp } from 'react-feather'

const ManageEmployees: FunctionComponent = () => {
  const { employees, getEmployees, searchTerm, indexPageNumber, indexRowsPerPage, sortState } = useEmployeeStore()

  const [displayedEmployees, setDisplayedEmployees] = React.useState(Object.values(employees))

  const fuse = React.useMemo(() => {
    return new Fuse<Employee>(Object.values(employees), {
      keys: [
        { name: 'name', getFn: (e) => e.name || '' },
      ],
      distance: 0,
      threshold: 0,
      useExtendedSearch: true,
    })
  }, [employees])

  React.useEffect(() => {
    getEmployees()
  }, [getEmployees])
  const employeeSort = useCallback((employee1: Employee, employee2: Employee): number => {
    if (!sortState.key) {
      return 0;
    }

    const emp1Value = employee1[sortState.key] || '';
    const emp2Value = employee2[sortState.key] || '';

    if (emp1Value === emp2Value) {
      return 0;
    }

    if (typeof emp1Value === 'string' && emp1Value.length === 0) {
      // Empty string shown at the bottom of the list (After Z)
      return sortState.ascending ? 1 : -1;
    }

    if (typeof emp2Value === 'string' && emp2Value.length === 0) {
      // Empty string shown at the bottom of the list (After Z)
      return sortState.ascending ? -1 : 1;
    }

    if (emp1Value > emp2Value) {
      return sortState.ascending ? 1 : -1;
    } else if (emp2Value > emp1Value) {
      return sortState.ascending ? -1 : 1;
    } else {
      return 0;
    }
  }, [sortState.ascending, sortState.key])

  const [showActive, setShowActive] = useState(true);

  const [showArchived, setShowArchived] = useState(true);


  React.useEffect(() => {
    let displayedEmployees = Object.values(employees);
    if (!showActive) {
      displayedEmployees = displayedEmployees.filter((employee) => employee.archivedAt);
    }
    if (!showArchived) {
      displayedEmployees = displayedEmployees.filter((employee) => !employee.archivedAt);
    }

    if (searchTerm) {
      const terms = searchTerm.split(' ').map((t) => t.replace(/\(\)-\*/g, ''))
      displayedEmployees = fuse.search(terms.join("|")).map((r) => r.item)
    }

    if (sortState.active) {
      displayedEmployees.sort(employeeSort)
    }
    setDisplayedEmployees(displayedEmployees)
  }, [searchTerm, employees, sortState, showActive, showArchived, fuse, employeeSort])

  React.useEffect(() => {
    useEmployeeStore.setState({ sortState: { ...useEmployeeStore.getState().sortState, active: !searchTerm.length } })
  }, [searchTerm])

  useEffect(() => {
    getEmployees()
  }, [getEmployees])

  const styles = useStyles()

  const navigate = useNavigate()

  const handleSearchChange = (newValue: string) => {
    useEmployeeStore.setState({ searchTerm: newValue, indexPageNumber: 0 })
  }

  const handleAddEmployeeClick = () => {
    navigate('/employees/new');
  }
  const handleRowClicked = (id: string) => {
    navigate(`/employees/${id}`);
  }

  const handleHeaderClicked = (key: EmployeeSortableId) => {
    const isSameKey = key === sortState.key
    let ascending = true
    if (isSameKey && sortState.active && sortState.ascending) {
      ascending = false
    }
    const newState = {
      active: true,
      ascending,
      key
    }

    useEmployeeStore.setState({ sortState: newState });
  }

  interface HeaderCellProps {
    id: EmployeeSortableId,
  }

  const HeaderCell = ({ id, children }: PropsWithChildren<HeaderCellProps>) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const cellSelected = sortState.key === id && sortState.active;
    const showIcon = isHovered || cellSelected;
    const opacity = cellSelected ? 1 : 0.2
    let Icon = ArrowDown;
    if (cellSelected && !sortState.ascending) {
      Icon = ArrowUp
    }
    return (
      <TableCell onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} style={{ cursor: 'pointer' }} onClick={() => handleHeaderClicked(id)}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {children}<Box style={{ width: 25, height: 25, marginLeft: 4 }}>{showIcon ? <Icon size={22} opacity={opacity} /> : null}</Box>
        </Box>
      </TableCell>
    )
  }

  const EmployeesTable = () => {
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      const rows = parseInt(event.target.value, 10);
      useEmployeeStore.setState({ indexPageNumber: 0, indexRowsPerPage: rows })
    };

    return (
      <>
        <TableContainer className={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <HeaderCell id={EmployeeSortableId.name}>Name</HeaderCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableContainer}>
              {displayedEmployees.slice(indexPageNumber * indexRowsPerPage, indexPageNumber * indexRowsPerPage + indexRowsPerPage).map((employee: any) => {
                return (
                  <TableRow hover className={styles.row} key={employee.id} onClick={() => handleRowClicked(employee.id)}>
                    <TableCell>{employee.name}</TableCell>
                    <TableCell>
                      {employee.archivedAt ?
                        <Pill color='red' value='Archived'>'Archived'</Pill> :
                        <Pill color='green' value='Active'> 'Active'</Pill>}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ flex: '0 1 auto' }}
          component="div"
          count={displayedEmployees.length}
          page={indexPageNumber}
          rowsPerPage={indexRowsPerPage}
          rowsPerPageOptions={[10, 30, 50]}
          onPageChange={(_, newPageIndex: number) => useEmployeeStore.setState({ indexPageNumber: newPageIndex })}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    )
  }

  const handleActiveCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowActive(event.target.checked);
  }

  const handleArchivedCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowArchived(event.target.checked);
  }

  return (
    <Box p={2}>
      <Box mb={2} className={styles.headingContainer}>
        <Box>
          <Typography variant={'h3'}>
            Manage Employees
          </Typography>
          <Typography variant={'caption'}>
            Add, edit, and archive employees.
          </Typography>
        </Box>
        <Box mr={3} mt={1}>
          <Button onClick={handleAddEmployeeClick} className={styles.addButton}>
            Add Employee +
          </Button>
        </Box>
      </Box>
      <Box mb={2} mt={5} className={styles.controlsContainer}>
        <Box mr={5} className={styles.searchContainer}>
          <SearchBar fullWidth value={searchTerm} onChange={handleSearchChange} />
        </Box>
        <Box ml={5} className={styles.controlsContainer}>
          <Typography variant={'subtitle1'} className={styles.boldText}>
            Filter List:
          </Typography>
          <Box ml={2} className={styles.controlsContainer}>
            <FormControlLabel
              control={<Checkbox checked={showActive} onChange={handleActiveCheckboxChange} />}
              label={<Typography className={styles.checkboxLabel}>Active</Typography>}
            />
            <FormControlLabel
              control={<Checkbox checked={showArchived} onChange={handleArchivedCheckboxChange} />}
              label={<Typography className={styles.checkboxLabel}>Archived</Typography>}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <EmployeesTable />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  tableContainer: {
    height: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftMenuContainer: {
    width: 300,
    height: '100%',
    borderRight: `2px solid ${colors.GREY_6}`
  },
  closeButton: {
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "100%",
    background: colors.GREY_2,
    cursor: 'pointer',
  },
  closeContainer: {
    position: 'relative',
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    borderTopLeftRadius: 20,
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  addButton: {
    marginLeft: 'auto'
  },
  searchContainer: {
    width: '40%',
    flex: '0 1 auto',
  },
  boldText: {
    fontWeight: 'bold',
  },
  row: {
    cursor: 'pointer',
  },
  mrr: {
    color: colors.DARK_GREEN,
    fontWeight: 'bold',
  },
  checkboxLabel: {
    fontSize: '0.8rem',
  },
})

export default ManageEmployees
