import { CircularProgress } from '@material-ui/core';
import moment from 'moment'
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react'
import Table from '../../components/Table';
import { SubscriptionSimple } from '../../types';
import colors from '../../colors';
import Pill from '../../components/Pill';
import useSubscriptionStore from '../../stores/subscription';
import useMembershipsStore from '../../stores/memberships';

interface Props {
  id: string;
}

const MembershipSubscriptionsTable: FC<Props> = ({ id }) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [subscriptions, setSubscriptions] = React.useState<SubscriptionSimple[]>([])
  const { getSubscriptionsForMembership } = useSubscriptionStore()
  const styles = useStyles()

  React.useEffect(() => {
    (async () => {
      const response = await getSubscriptionsForMembership(id)
      setSubscriptions(response.data)
      setLoading(false)
    })()
  }, [id, getSubscriptionsForMembership])

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <Table<SubscriptionSimple>
        title={'Subscriptions'}
        rows={subscriptions}
        loading={loading}
        filters={[
          {
            title: 'Status',
            matchingFunction: (s) => s.status
          },
          {
            title: 'Billing Interval',
            matchingFunction: (s) => s.interval
          },
          {
            title: 'Price',
            matchingFunction: (s) => s.price
          },
          {
            title: 'Auto Renew',
            matchingFunction: (s) => s.autoRenew ? 'On' : 'Off',
          },
        ]}
        columns={[{
          label: 'First Name',
          cellFill: (sub: SubscriptionSimple) => sub.patron.firstName,
          searchable: true,
          searchFunction: (s) => String(s.patron.firstName),
          csv: true,
          sortValueGetter: (s) => String(s.patron.firstName),
        },
        {
          label: 'Last Name',
          cellFill: (sub: SubscriptionSimple) => sub.patron.lastName,
          searchable: true,
          searchFunction: (s) => String(s.patron.lastName),
          csv: true,
          sortValueGetter: (s) => String(s.patron.lastName),
        },
        {
          label: 'Email',
          cellFill: (sub: SubscriptionSimple) => sub.patron.email,
          searchable: true,
          searchFunction: (s) => String(s.patron.email),
          hide: true,
          csv: true,
          sortValueGetter: (s) => String(s.patron.email),
        },
        {
          label: 'Membership',
          cellFill: (sub: SubscriptionSimple) => sub.membershipTitle,
          csv: true,
          sortValueGetter: (s) => String(s.membershipTitle),
        },
        {
          label: 'Price',
          cellFill: (sub: SubscriptionSimple) => sub.price,
          sortValueGetter: (s) => Number(String(s.price).replace('$', '').replace(',', '')),
          csv: true,
        },
        {
          label: 'Cycle Length',
          cellFill: (sub: SubscriptionSimple) => sub.interval,
          sortValueGetter: (s) => String(s.interval),
          csv: true,
        },
        {
          label: 'Start Date',
          cellFill: (sub: SubscriptionSimple) => moment(sub.startDate).format('M/D/YYYY'),
          sortValueGetter: (s) => new Date(s.startDate),
          csv: true,
        },
        {
          label: 'Current Period End',
          cellFill: (sub: SubscriptionSimple) => moment(sub.currentCycleEnd).format('M/D/YYYY'),
          sortValueGetter: (s) => new Date(s.currentCycleEnd),
          csv: true,
        },
        {
          label: 'Status',
          cellFill: 'status',
          sortValueGetter: (s) => String(s.status),
          csv: true,
        },
        {
          label: 'Auto Renew',
          cellFill: (sub: SubscriptionSimple) => sub.autoRenew ? <Pill color='green' value='On' /> : <Pill color='grey' value='Off' />,
          csv: true,
          csvFill: (sub: SubscriptionSimple) => sub.autoRenew ? 'On' : 'Off',
          sortValueGetter: (s) => s.autoRenew ? 'on' : 'off',
        },
        ]}
        handleRowClicked={(row: SubscriptionSimple) => { }}
        tableStateStore={useMembershipsStore}
      ></Table>
    </div >
  )
}

const useStyles = makeStyles({
  loadingContainer: {
    width: '100%',
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    overflow: 'hidden',
    flex: '1 1 auto',
    boxSizing: 'border-box',
    flexDirection: 'column',
    color: colors.STITCH_BLACK,
    padding: 24,
  },
})

export default MembershipSubscriptionsTable