import { FunctionComponent } from 'react'
import MailChimpSettings from './MailChimpSettings'
import StripeSettings from './StripeSettings'
import { Routes, Route } from 'react-router-dom'
import Submenu from '../../components/Submenu'
import Integrations from './Integrations'
import {
  Zap
} from 'react-feather'

const Settings: FunctionComponent = () => {
  return (
    <Routes>
      <Route path='/integrations/stripe/connect' element={<StripeSettings />} />
      <Route path='/integrations/mailchimp' element={<MailChimpSettings />} />
      <Route element={
        <Submenu
          basePath={'/settings'}
          items={[
            {
              id: 'integrations',
              icon: Zap,
              title: 'Integrations',
              bodyComponent: Integrations,
              subPath: ''
            }
          ]}
        />}
      >
        <Route index element={<Integrations />} />
      </Route>
    </Routes>
  )
}

export default Settings
