import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import { Patron, PatronSortableId } from '../types'
import axios from '../utils/axios'
import { toast } from 'react-toastify'
import useSubscriptionStore from './subscription'
import { SortState } from '../types'

type PatronsObject = Record<string, Patron>

type Store = {
  patrons: PatronsObject,
  getPatrons: () => void,
  fetchSubscriptionsForPatron: (patronId: string) => Promise<void>,
  getPatron: (id: string) => void,
  updatePatron: (patron: Patron) => Promise<void>,
  rehydrated: boolean,
  setRehydrated: (isRehydrated: boolean) => void,
  searchTerm: string,
  sortState: SortState,
  indexPageNumber: number,
  indexRowsPerPage: number,
}

const createFunction = (set: Function, _: Function): Store => ({
  patrons: {},
  getPatrons: async () => {
    const response = await axios.get('/patrons')
    const patronsArray = response.data.patrons
    const patrons = {} as PatronsObject

    patronsArray.forEach((patron: Patron) => {
      patrons[patron.id] = patron
    })
    const subscriptions = patronsArray.map((p: Patron) => p.subscriptions).flat()

    useSubscriptionStore.getState().addSubscriptions(subscriptions)

    set((state: Store) => ({ ...state, patrons }))
  },
  getPatron: async (patronId: string): Promise<void> => {
    const resp = await axios.get(`/patrons/${patronId}`)
    set((state: Store) => ({ ...state, patrons: { ...state.patrons, [patronId]: resp.data }, }))
  },
  fetchSubscriptionsForPatron: async (patronId: string) => {
    await axios.get(`/patrons/${patronId}/subscriptions`)
  },
  updatePatron: async (patron: Patron): Promise<void> => {
    try {
      const resp = await axios.patch(`/patrons/${patron.id}`, {
        patron
      })

      set((state: Store) => ({ ...state, patrons: { ...state.patrons, [patron.id]: resp.data }, }))
    } catch (e: any) {
      toast.error(`Error updating patron. Status: ${e.response.status}`)
    }
  },
  rehydrated: false,
  setRehydrated: (isRehydrated: boolean) => set({ rehydrated: isRehydrated }),
  sortState: {
    ascending: true,
    key: PatronSortableId.firstName,
  },
  indexPageNumber: 0,
  indexRowsPerPage: 10,
  searchTerm: ''
})

export const usePatronStore = create(devtools(persist(createFunction, {
  name: 'stitchPatrons',
  partialize: (state: Store) => ({ patrons: state.patrons }),
  onRehydrateStorage: (_: Store) => {
    return ((state?: Store | undefined, error?: unknown): void => {
      if (!state) {
        throw error
      }

      state.setRehydrated(true)
    })
  },
})))

export default usePatronStore
