import React, { useState, PropsWithChildren, useCallback } from 'react';
import { CSVLink } from 'react-csv';
import { Patron, PatronSortableId } from '../../../src/types';
import {
  Box,
  Button, TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { ArrowDown, ArrowUp } from 'react-feather';
import { makeStyles } from '@material-ui/styles';
import SearchBar from '../../components/SearchBar';
import colors from '../../colors';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import usePatronStore from '../../stores/patron';

export default function Page() {
  const navigate = useNavigate()
  const { patrons, getPatrons, searchTerm, indexPageNumber, sortState, indexRowsPerPage } = usePatronStore()
  const [displayedPatrons, setDisplayedPatrons] = React.useState(Object.values(patrons))
  const fuse = React.useMemo(() => {
    return new Fuse<Patron>(Object.values(patrons), {
      keys: [
        { name: 'email', getFn: (p) => p.email || '' },
        { name: 'firstName', getFn: (p) => p.firstName || '' },
        { name: 'lastName', getFn: (p) => p.lastName || '' },
        { name: 'phone', getFn: (p) => (p.phone || '').replace(/\W/g, '') },
      ],
      distance: 0,
      threshold: 0,
      useExtendedSearch: true,
    })
  }, [patrons]);
  const csvData = React.useMemo(() => (displayedPatrons.map((patron) => [patron.firstName, patron.lastName, patron.email, patron.phone, patron.shirtSize])), [displayedPatrons])

  React.useEffect(() => {
    getPatrons()
  }, [getPatrons])
  const patronSort = useCallback((patron1: Patron, patron2: Patron): number => {
    if (!sortState.key) {
      return 0
    };

    const pat1Value = patron1[sortState.key] || '';
    const pat2Value = patron2[sortState.key] || '';

    if (pat1Value === pat2Value) {
      return 0;
    }

    if (!pat1Value.length) {
      // Empty string shown at bottom of list (After Z)
      return sortState.ascending ? 1 : -1
    }

    if (!pat2Value.length) {
      // Empty string shown at bottom of list (After Z)
      return sortState.ascending ? -1 : 1
    }

    if (pat1Value > pat2Value) {
      return sortState.ascending ? 1 : -1
    } else if (pat2Value > pat1Value) {
      return sortState.ascending ? -1 : 1
    } else {
      return 0
    }
  }, [sortState.ascending, sortState.key])

  // Handle search and sorting
  React.useEffect(() => {
    let displayedPatrons = Object.values(patrons);
    if (searchTerm) {
      const terms = searchTerm.split(' ').map((t) => t.replace(/\(\)-\*/g, ''))
      displayedPatrons = fuse.search(terms.join("|")).map((r) => r.item)
    }

    if (sortState.active) {
      displayedPatrons.sort(patronSort)
    }
    setDisplayedPatrons(displayedPatrons)
  }, [searchTerm, patrons, sortState, fuse, patronSort])

  React.useEffect(() => {
    usePatronStore.setState({ sortState: { ...usePatronStore.getState().sortState, active: !searchTerm.length } })
  }, [searchTerm])

  const handleSearchChange = (newValue: string) => {
    usePatronStore.setState({ searchTerm: newValue, indexPageNumber: 0 })
  }


  const styles = useStyles()

  const handleRowClicked = (patronId: string) => {
    navigate(`/patrons/${patronId}`)
  }

  const handleHeaderClicked = (key: PatronSortableId) => {
    const isSameKey = key === sortState.key
    let ascending = true
    if (isSameKey && sortState.active && sortState.ascending) {
      ascending = false
    }
    const newState = {
      active: true,
      ascending,
      key
    }

    usePatronStore.setState({ sortState: newState });
  }

  interface HeaderCellProps {
    id: PatronSortableId,
  }

  const HeaderCell = ({ id, children }: PropsWithChildren<HeaderCellProps>) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const cellSelected = sortState.key === id && sortState.active;
    const showIcon = isHovered || cellSelected;
    const opacity = cellSelected ? 1 : 0.2
    let Icon = ArrowDown;
    if (cellSelected && !sortState.ascending) {
      Icon = ArrowUp
    }
    return (
      <TableCell onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} style={{ cursor: 'pointer' }} onClick={() => handleHeaderClicked(id)}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {children}<Box style={{ width: 25, height: 25, marginLeft: 4 }}>{showIcon ? <Icon size={22} opacity={opacity} /> : null}</Box>
        </Box>
      </TableCell>
    )
  }


  const PatronsTable = () => {
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      const rows = parseInt(event.target.value, 10);
      usePatronStore.setState({ indexPageNumber: 0, indexRowsPerPage: rows })
    };

    return (
      <>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>

                <HeaderCell id={PatronSortableId.firstName}>First Name</HeaderCell>
                <HeaderCell id={PatronSortableId.lastName}>Last Name</HeaderCell>
                <HeaderCell id={PatronSortableId.email}>Email</HeaderCell>
                <HeaderCell id={PatronSortableId.phone}>Phone</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableContainer}>
              {displayedPatrons.slice(indexPageNumber * indexRowsPerPage, indexPageNumber * indexRowsPerPage + indexRowsPerPage).map((patron: any) => {
                return (
                  <TableRow hover className={styles.row} key={patron.id} onClick={() => handleRowClicked(patron.id)}>
                    <TableCell>{patron.firstName}</TableCell>
                    <TableCell>{patron.lastName}</TableCell>
                    <TableCell>{patron.email}</TableCell>
                    <TableCell>{patron.phone}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ flex: '0 1 auto' }}
          component="div"
          count={displayedPatrons.length}
          page={indexPageNumber}
          rowsPerPage={indexRowsPerPage}
          rowsPerPageOptions={[10, 30, 50]}
          onPageChange={(_, newPageIndex: number) => usePatronStore.setState({ indexPageNumber: newPageIndex })}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    )
  }

  return (
    <Box className={styles.container}>
      <Box mb={2} className={styles.controlsContainer}>
        <Box mr={2} className={styles.searchContainer}>
          <SearchBar fullWidth value={searchTerm} onChange={handleSearchChange} />
        </Box>
      </Box>
      <Box className={styles.exportContainer}>
        <Box mr={2}>
          <h3>{displayedPatrons.length} Patrons</h3>
        </Box>
        <Button variant="text">
          <CSVLink data={csvData} filename={"stitch_patrons.csv"} headers={['First Name', 'Last Name', 'Email', 'Phone Number', 'Shirt Size']}>
            Export CSV
          </CSVLink>
        </Button>
      </Box>
      <PatronsTable />
    </Box>
  )
}

const useStyles = makeStyles({
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  tableContainer: {
    height: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftMenuContainer: {
    width: 300,
    height: '100%',
    borderRight: `2px solid ${colors.GREY_6}`
  },
  closeButton: {
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "100%",
    background: colors.GREY_2,
    cursor: 'pointer',
  },
  closeContainer: {
    position: 'relative',
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    borderTopLeftRadius: 20,
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchContainer: {
    width: '40%',
    flex: '0 1 auto',
  },
  boldText: {
    fontWeight: 'bold',
  },
  row: {
    cursor: 'pointer',
  },
  mrr: {
    color: colors.DARK_GREEN,
    fontWeight: 'bold',
  },
})
