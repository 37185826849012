import { useEffect, FunctionComponent } from 'react'
import { Routes, useParams, Route } from 'react-router-dom'
import { User, Heart } from 'react-feather'
import Submenu from '../../components/Submenu'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import ProfileForm from './ProfileForm'
import BenefitsView from './BenefitsView'
import usePatronStore from '../../stores/patron'

const PatronDetail: FunctionComponent = () => {
  const { getPatron } = usePatronStore()
  const { id } = useParams<{ id: string }>()
  const patron = usePatronStore((state) => state.patrons[id as string])

  useEffect(() => {
    getPatron(id as string)
  }, [id, getPatron])

  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Patrons', linkPath: '/patrons' },
        { title: patron ? patron.email : '', linkPath: '' },
      ]} closePath={'/patrons'}
      />
      <Routes>
        <Route element={
          <Submenu
            basePath={`/patrons/${id}`}
            items={[
              {
                id: 'profile',
                icon: User,
                title: 'Patron Profile',
                subPath: ''
              },
              {
                id: 'benefits',
                icon: Heart,
                title: 'Benefits',
                subPath: '/benefits'
              }
            ]}
          />}>
          <Route index element={<ProfileForm patronId={id as string} />} />
          <Route path="/benefits" element={<BenefitsView patronId={id as string} />} />
        </Route>
      </Routes>
    </DetailOverlayPage>
  )
}

export default PatronDetail
