import { create } from 'zustand'
import { SortState } from '../types'

type Store = {
  searchTerm: string,
  sortState: SortState,
  indexPageNumber: number,
  indexRowsPerPage: number,
}

type StoreActions = {
  setSearchTerm: (store: string, searchTerm: string) => void,
  setSortState: (store: string, sortState: SortState) => void,
  setIndexPageNumber: (store: string, indexPageNumber: number) => void,
  setIndexRowsPerPage: (store: string, indexRowsPerPage: number) => void,
}

const createFunction = (set: Function, _: Function): Store => ({
  searchTerm: '',
  sortState: {
    key: 'First Name',
    ascending: true,
  },
  indexPageNumber: 0,
  indexRowsPerPage: 10,
})

export const useTableSortStore = create(createFunction)

export default useTableSortStore
