import { Routes, Route } from 'react-router-dom'
import Submenu from '../../components/Submenu'
import ManageEmployees from './ManageEmployees'
import {
  Users,
  BarChart2
} from 'react-feather'
import NewEmployee from './NewEmployee'
import EmployeeDetail from './EmployeeDetail'
import SalesAttributions from './SalesAttributions'

export default function Employees() {
  return (
    <>
      <Routes>
        <Route element={
          <Submenu
            basePath={"/employees"}
            items={[
              { icon: Users, title: 'Manage Employees', subPath: "", id: 'manageEmployees' },
              { icon: BarChart2, title: 'Sales Attributions', subPath: "/sales_attributions", id: 'sales_attributions' }
            ]}
          />}
        >
          <Route index element={<ManageEmployees />} />
          <Route path={"sales_attributions"} element={<SalesAttributions />} />
        </Route>
        <Route path='new' element={<NewEmployee />} />
        <Route path=':id' element={<EmployeeDetail />} />
      </Routes>
    </>
  )
}