import { useState, useEffect, FunctionComponent } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { CircularProgress, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import { SPACING } from '../../constants'
import axios from '../../utils/axios'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import { toast } from 'react-toastify'
import queryString from 'query-string'

const LOGO_SIZE = 150

const ConnectStripe: FunctionComponent = () => {
  const styles = useStyles()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    (async () => {
      const { code } = queryString.parse(location.search)

      if (code) {
        try {
          await axios.post('/organization/connect_to_stripe', { code })
          setSuccess(true)
          setLoading(false)
          toast.success('Successfully connected to Stripe!')
        } catch {
          setSuccess(false)
          setLoading(false)
          toast.error('Connection failed!')
        }
      }
    })()
  }, [location])

  const SuccessMessage = () => {
    return (
      <Box>
        <h4>Connected! 🎉</h4>
        <p>You have successfully connected to Stripe.</p>
        <p>When patrons buy memberships, we'll route the money to your Stripe account.</p>
        <Link to="/settings">
          <Button color="primary">
            Done
          </Button>
        </Link>
      </Box>
    )
  }

  const ErrorMessage = () => {
    return (
      <Box>
        <h4>Uh Oh!</h4>
        <p>Something went wrong.</p>
        <p>Please try again. If the problem persists, <a href='mailto:help@stitch.vip'>contact us</a></p>
        <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_STRIPE_REDIRECT_URI}`}>
          <Button color="primary">
            Try Again
          </Button>
        </a>
      </Box>
    )
  }

  const Message = () => {
    if (success) { return <SuccessMessage /> }
    return <ErrorMessage />
  }

  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Settings', linkPath: '/settings' },
        { title: 'Integrations', linkPath: '/settings' },
        { title: 'Stripe', linkPath: '/settings/integrations/stripe' },
      ]} closePath={'/settings'}
      />
      <Box className={styles.bodyContainer}>
        <Box className={styles.formContainer}>
          <Box className={styles.banner}>
            <img src={'/connect_stripe.png'} alt="Stripe Logo" width={LOGO_SIZE} height={LOGO_SIZE} />
          </Box>
          <Box className={styles.buttonContainer}>
            <Box mr={1}>
              { loading
                ? <CircularProgress size={18} className={styles.progress} />
                : <Message />
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </DetailOverlayPage>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    borderTop: `8px solid ${colors.BRIGHT_BLUE}`,
    background: 'white',
    top: 0,
    left: 0,
  },
  formTitle: {
    fontWeight: 'bold'
  },
  formContainer: {
    marginTop: SPACING * 4,
    display: 'flex',
    width: 300,
    flexDirection: 'column',
  },
  banner: {
    width: '100%',
    borderRadius: 6,
    backgroundColor: '#32325D',
    marginBottom: SPACING * 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logosContainer: {
    display: 'flex',
    marginBottom: SPACING * 4,
  },
  buttonContainer: {
    display: 'flex',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9,
  },
  submitContainer: {
    position: 'relative'
  },
  bodyContainer: {
    display: 'flex',
    width: '100vw',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
export default ConnectStripe
