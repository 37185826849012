import { useEffect, FunctionComponent, useState } from 'react'
import { Route, Routes, redirect, useNavigate, useParams } from 'react-router-dom'
import { User, Award, DollarSign, } from 'react-feather'
import Submenu from '../../components/Submenu'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import useMembershipsStore from '../../stores/memberships'
import MembershipSubscriptionsTable from './MembershipSubscriptionsTable'

const MembershipDetail: FunctionComponent = () => {
  const { getSimpleMembership, memberships } = useMembershipsStore()
  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  useEffect(() => {
    if (!id) {
      return
    }

    getSimpleMembership(id)
  }, [id, getSimpleMembership])

  if (!id) {
    navigate('/memberships')
    return null
  }

  const membership = memberships[id]

  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Memberships', linkPath: '/memberships' },
        { title: membership?.title, linkPath: '' },
      ]} closePath={'/memberships'}
      />
      <Routes>
        <Route element={
          <Submenu
            basePath={`/memberships/${id}`}
            items={[
              {
                id: 'subscriptions',
                icon: User,
                title: 'Subscriptions',
                subPath: ''
              },
              // {
              //   id: 'benefits',
              //   icon: Award,
              //   title: 'Benefits',
              //   subPath: '/benefits'
              // },
              // {
              //   id: 'pricePoints',
              //   icon: DollarSign,
              //   title: 'Price Points',
              //   subPath: '/price_points'
              // },
            ]}
          />
        }>
          <Route index element={<MembershipSubscriptionsTable id={id} />} />
        </Route>
      </Routes>
    </DetailOverlayPage>
  )
}

export default MembershipDetail
