import { FunctionComponent } from 'react'

export type SubmenuItem = {
  id: string,
  icon: FunctionComponent,
  title: string,
  subPath: string,
}

export interface MenuItem {
  title: string,
  Icon: FunctionComponent,
  path: string,
  id: string,
}

export interface Membership {
  title: string;
  id: string;
  uuid: string;
  activeSubscriptionCount: number;
  arr: string;
  status: string;
}

export interface PatronSimple {
  email: string;
  id: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}
export interface Patron extends PatronSimple {
  subscriptions?: Subscription[];
  tags?: PatronTag[];
  shirtSize?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface EmployeeParams {
  name: string;
  archivedAt?: Date;
}
export interface MembershipParams {
  title: string;
}
export interface EmployeeParams {
  name: string;
  archivedAt?: Date;
}

export interface Employee extends EmployeeParams {
  id: string;
  createdAt: Date;
}


export type PatronTag = Record<string, string>

export interface Benefit {
  id: string,
  title: string,
  details: string,
  quantity: number,
}

export interface BenefitCycle {
  id: string,
  patronageBenefitIds: string[],
  patronageBenefits?: PatronBenefit[],
  status: string,
  nextBenefitCycleId?: string,
  previousBenefitCycleId?: string,
  startDate: string,
  endDate: string,
}

export interface SubscriptionSimple {
  id: string;
  membershipTitle: string;
  status: string;
  currentCycleEnd: string;
  startDate: string;
  patronName?: string;
  autoRenew: string;
  patron: Patron;
  price: string;
  interval: string;
}
export interface Subscription extends SubscriptionSimple {
  benefitCycles: Record<string, BenefitCycle>;
  currentBenefitCycleId: string;
  benefits: PatronBenefit[];
  renewalText?: string;
  membership: Membership;
}


export interface PatronBenefit {
  id: string,
  uuid: string,
  startDate: string,
  name: string,
  redeemedAt: string,
  issuedOn: string,
  expirationDate: string,
  status: ('available' | 'redeemed' | 'expired'),
}

export interface PatronSubscription {
  id: string,
  interval: ('month' | 'year' | 'once'),
  name: string,
  renewalDate: string,
  status: ('active' | 'canceled' | 'pending_cancel' | 'pending_active'),
  benefits: Benefit[],
  patronBenefits: Benefit[],
}

export enum PatronSortableId {
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  email = 'email',
}

export interface SortState {
  key: string,
  ascending: boolean,
}

export enum EmployeeSortableId {
  name = 'name',
  status = 'status'
}

export interface EmployeeSortState {
  key: EmployeeSortableId,
  ascending: boolean,
  active: boolean,
}
