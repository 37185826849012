import React, { useState, PropsWithChildren, useCallback, ReactNode, FC, useMemo, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
  Box,
  Button, TableContainer,
  TablePagination,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Collapse,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import { StoreApi, UseBoundStore } from 'zustand'
import { ArrowDown, ArrowUp, Filter as FilterIcon } from 'react-feather';
import { makeStyles } from '@material-ui/styles';
import SearchBar from './SearchBar';
import colors from '../colors';
import Fuse from 'fuse.js';
import useTableStore from '../stores/tableState';
import { Checkbox } from '@material-ui/core';
import _ from 'lodash';

interface Column<T> {
  label: string;
  cellFill: keyof T | ((row: T) => ReactNode | React.ComponentType<any>);
  sortable?: boolean;
  searchable?: boolean;
  searchFunction?: (row: T) => string;
  hide?: boolean;
  sortValueGetter?: (row: T) => string | number | Date;
  csv?: boolean;
  csvFill?: (row: T) => string | number;
}

interface Filter<T> {
  title: string;
  matchingFunction: (row: T) => string
}


interface TableStateStore {
  (): TableState;
  setState: (state: Partial<TableState>) => void
}

type SortDirection = 'asc' | 'desc' | null
export interface TableState {
  searchTerm: string;
  indexPageNumber: number;
  indexRowsPerPage: number;
  sortColumnId?: string | null;
  sortDirection: SortDirection;
}

interface Props<T> {
  title: string;
  rows: T[],
  search?: boolean;
  columns: Column<T>[];
  handleRowClicked: (row: T) => void;
  loading: boolean;
  filters?: Filter<T>[];
  tableStateStore: TableStateStore;
}

interface SortState<T> {
  columnId?: string;
  ascending: boolean;
}

interface FilterOption {
  id: string;
  displayLabel: string;
}
interface FilterOptionGroup {
  title: string;
  options: FilterOption[];
}

const Table = <T,>({ rows, columns, handleRowClicked, title, filters, tableStateStore }: Props<T>) => {
  const { searchTerm, indexPageNumber, indexRowsPerPage, sortState } = useTableStore()
  const defaultSortColumn = columns.find((c) => c.sortable)
  const [displayedRows, setDisplayedRows] = React.useState(rows)
  const [filtersOn, toggleFiltersOn] = useState<boolean>(false);
  const [selectedFilterIds, setSelectedFilters] = useState<string[]>([])
  const filterOptionGroups = useMemo((): FilterOptionGroup[] => {
    if (!filters) {
      return []
    }

    return (
      filters.map((f: Filter<T>) => {
        const optionLabels: string[] = _.uniq(rows.map((r: T) => f.matchingFunction(r)))
        return (
          {
            title: f.title,
            options: optionLabels.sort().map((label) => ({
              id: `${f.title}-${label}`,
              displayLabel: label,
            }))
          }
        )
      })
    )
  }, [filters, rows])
  const fuse = React.useMemo(() => {
    return new Fuse<T>(rows, {
      keys: columns.filter((c) => c.searchable).map((c) => ({ name: c.label, getFn: c.searchFunction })),
      distance: 0,
      threshold: 0,
      useExtendedSearch: true,
    })
  }, [rows, columns]);

  const sort = useCallback((one: T, two: T): number => {
    if (!sortState.key) {
      return 0
    };
    const sortingColumn = columns.find((c) => c.label === sortState.key)
    if (!sortingColumn || !sortingColumn.sortValueGetter) {
      return 0
    }

    const oneValue = sortingColumn.sortValueGetter(one)
    const twoValue = sortingColumn.sortValueGetter(two)

    if (oneValue === twoValue) {
      return 0;
    }

    if (!oneValue) {
      // Empty string shown at bottom of list (After Z)
      return sortState.ascending ? 1 : -1
    }

    if (!twoValue) {
      // Empty string shown at bottom of list (After Z)
      return sortState.ascending ? -1 : 1
    }

    if (oneValue > twoValue) {
      return sortState.ascending ? 1 : -1
    } else if (twoValue > oneValue) {
      return sortState.ascending ? -1 : 1
    } else {
      return 0
    }
  }, [sortState])


  // RUN THE FILTERS
  useEffect(() => {
    let filteredRows = [...rows]

    const filterIds = selectedFilterIds.sort()

    // Filter ids are in the format `${filterLabel}-${value}`
    // This transforms an array of those filter ids into an object that summarizes
    // the active filters:
    // ["Price-$100", "Price-$200", "Interval-monthly", "Interval-annual", "Auto Renew-On"]
    // Becomes this:
    // {
    //   Price: ["$100", "$200"],
    //   Interval: ["monthly", "annual"],
    //   "Auto Renew": ["On"],
    // }
    const activeFilters = _.chain(selectedFilterIds)
      .groupBy(item => item.split('-')[0])
      .mapValues(groupedItems => groupedItems.map(item => item.split('-')[1]))
      .value();

    if (selectedFilterIds.length && filters && filtersOn) {
      Object.keys(activeFilters).forEach((activeFilterTitle: string) => {
        const filter = filters.find((filter) => filter.title === activeFilterTitle)
        if (!filter) {
          console.warn(`Filter not found by title ${activeFilterTitle}`)
          return
        }

        filteredRows = filteredRows.filter((row: T) => (
          activeFilters[activeFilterTitle].includes(filter.matchingFunction(row))
        ))
      })
    }

    if (searchTerm) {
      const terms = searchTerm.split(' ').map((t) => t.replace(/\(\)-\*/g, ''))
      filteredRows = fuse.search(terms.join("|")).map((r) => r.item)
    }

    filteredRows.sort(sort)
    setDisplayedRows(filteredRows)
  }, [rows, filtersOn, selectedFilterIds, searchTerm, filters, sortState, fuse, sort])

  const csvColumns = columns.filter((c) => c.csv)
  const csvData = React.useMemo(() => (csvColumns
    ? displayedRows.map((row) => (csvColumns.map((c) => {
      if (c.csvFill) {
        return c.csvFill(row)
      }

      if (typeof c.cellFill === 'function') {
        return c.cellFill(row)
      }

      return row[c.cellFill]
    }
    )))
    : []), [rows, csvColumns])


  const handleSearchChange = (newValue: string) => {
    useTableStore.setState({ searchTerm: newValue, indexPageNumber: 0 })
  }

  const styles = useStyles()

  const handleHeaderClicked = (selectedId: string) => {
    const isSameKey = selectedId === sortState.key
    let ascending = true
    if (isSameKey && sortState.key && sortState.ascending) {
      ascending = false
    }
    const newState = {
      ascending,
      key: selectedId
    }
    useTableStore.setState((s) => ({ ...s, sortState: newState }));
  }

  interface HeaderCellProps {
    id: string,
    key?: React.Key
  }

  const HeaderCell = ({ id, children, key }: PropsWithChildren<HeaderCellProps>) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const cellSelected = sortState.key && sortState.key === id;
    const showIcon = isHovered || cellSelected;
    const opacity = cellSelected ? 1 : 0.2
    let Icon = ArrowUp;
    if (cellSelected && !sortState.ascending) {
      Icon = ArrowDown
    }
    return (
      <TableCell key={key} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} style={{ cursor: 'pointer' }} onClick={() => handleHeaderClicked(id)}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {children}<Box style={{ width: 25, height: 25, marginLeft: 4 }}>{showIcon ? <Icon size={22} opacity={opacity} /> : null}</Box>
        </Box>
      </TableCell>
    )
  }

  const MainTable = () => {
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      const rows = parseInt(event.target.value, 10);
      useTableStore.setState({ indexPageNumber: 0, indexRowsPerPage: rows })
    };

    return (
      <>
        <TableContainer className={styles.tableContainer}>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, i) => {
                  if (column.hide) {
                    return null
                  }
                  return (
                    <HeaderCell id={column.label} key={i}><strong>{column.label}</strong></HeaderCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.slice(indexPageNumber * indexRowsPerPage, indexPageNumber * indexRowsPerPage + indexRowsPerPage).map((row: T, i: number) => {
                return (
                  <TableRow hover className={styles.row} key={i} onClick={() => handleRowClicked(row)}>
                    {columns.map((column, i) => {
                      if (column.hide) {
                        return null
                      }
                      return (
                        <TableCell key={i}>{typeof column.cellFill === 'function' ? column.cellFill(row) : row[column.cellFill]}</TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          style={{ overflow: 'hidden' }}
          component="div"
          count={displayedRows.length}
          page={indexPageNumber}
          rowsPerPage={indexRowsPerPage}
          rowsPerPageOptions={[10, 30, 50]}
          onPageChange={(_, newPageIndex: number) => useTableStore.setState({ indexPageNumber: newPageIndex })}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    )
  }

  const handleSelectFilter = (id: string, selected: boolean) => {
    const selectedIds = selectedFilterIds.filter((filterId) => filterId !== id)

    if (selected) {
      selectedIds.push(id)
    }

    setSelectedFilters(selectedIds)
  }

  const Filters: FC = () => {
    if (!filters) {
      return null
    }

    return (
      <Box className={styles.filtersContainer}>
        {filterOptionGroups.map((fog: FilterOptionGroup) => {
          return (
            <Box className={styles.filterContainer}>
              <strong>{fog.title}</strong>
              <FormGroup>
                {fog.options.map((o) => {
                  const selected = selectedFilterIds.includes(o.id)
                  return (
                    <FormControlLabel control={<Checkbox onClick={() => handleSelectFilter(o.id, !selected)} checked={selected} />} label={o.displayLabel} />
                  )
                })}
              </FormGroup>
            </Box >
          )
        })
        }
      </Box >
    )
  }

  return (
    <Box className={styles.container}>
      <Box mb={2} className={styles.controlsContainer}>
        <Box mr={2} className={styles.searchContainer}>
          <SearchBar fullWidth value={searchTerm} onChange={handleSearchChange} />
        </Box>
        <Box>
          <Button variant="text" startIcon={<FilterIcon />} onClick={() => toggleFiltersOn(!filtersOn)}>
            Filter
          </Button>
        </Box>
      </Box>
      <Collapse in={filtersOn}>
        <Filters />
      </Collapse>
      <Box className={styles.exportContainer}>
        <Box mr={2}>
          <h3>{displayedRows.length} {title}</h3>
        </Box>
        <Button variant="text">
          <CSVLink data={csvData} filename={"Stitch.csv"} headers={csvColumns.map((c) => c.label)}>
            Export CSV
          </CSVLink>
        </Button>
      </Box>
      <MainTable />
    </Box>
  )
}

const useStyles = makeStyles({
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    minHeight: 0,
  },
  tableContainer: {
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftMenuContainer: {
    width: 300,
    height: '100%',
    borderRight: `2px solid ${colors.GREY_6}`
  },
  closeButton: {
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "100%",
    background: colors.GREY_2,
    cursor: 'pointer',
  },
  closeContainer: {
    position: 'relative',
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    borderTopLeftRadius: 20,
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchContainer: {
    width: '40%',
    flex: '0 1 auto',
  },
  boldText: {
    fontWeight: 'bold',
  },
  row: {
    cursor: 'pointer',
  },
  mrr: {
    color: colors.DARK_GREEN,
    fontWeight: 'bold',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24
  }
})

export default Table