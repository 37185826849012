import { FunctionComponent } from 'react'
import {
  LogOut as LogOutIcon,
} from 'react-feather'
import { MENU_ITEMS } from '../constants'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useAuthStore } from '../stores/auth'
import colors from '../colors'
import { SPACING } from '../constants'
import { Link } from 'react-router-dom'
import useCurrentAppLocation from '../hooks/useCurrentAppLocation'

const Nav: FunctionComponent = () => {
  const user = useAuthStore((state) => state.user)
  const logout = useAuthStore((state) => state.logout)

  const styles = useStyles()

  const MenuItem = ({ title, Icon, path, id }: { title: string, Icon: any, path: string, id: string}) => {
    const appLocation = useCurrentAppLocation()
    const isSelected = appLocation.id === id
    return (
      <Link key={title} to={path}>
        <li
          className={isSelected ? styles.liSelected : styles.li}
        >
          <Icon size={IconSize} className={isSelected ? styles.iconSelected : styles.icon} />
          {title}
        </li>
      </Link>
    )
  }

  const IconSize = '24'
  const menuItems = [MENU_ITEMS.dash, MENU_ITEMS.memberships, MENU_ITEMS.patrons, MENU_ITEMS.employees, MENU_ITEMS.settings, MENU_ITEMS.help]

  const MenuList = () => {
    return (
      <Box className={styles.menuListsContainer}>
        <ul className={styles.list}>
          {menuItems.map(({ ...props }) => (
            <MenuItem key={props.id} {...props} />
          ))}
        </ul>
        <ul className={styles.list}>
          <Link to="/settings/integrations">
            <li className={styles.li}>{user && user.email}</li>
          </Link>
          <div onClick={logout} className={styles.logout}>
            <li className={styles.li}>
              <LogOutIcon size={IconSize} className={styles.icon} />
              Log Out
            </li>
          </div>
        </ul>
      </Box>
    )
  }

  const Logo = () => {
    return (
      <img
        src={"/images/bare_logo.png"}
        alt="Stitch Logo"
        width={72}
        height={48}
      />
    )
  }

  return (
    <Box className={styles.container}>
      <Link to="/">
        <Box mb={2} className={styles.logoContainer}>
          <Logo />
        </Box>
      </Link>
      <Box className={styles.menuContainer}>
        <MenuList />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: SPACING * 2,
    display: 'flex',
    flexDirection: 'column',
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    height: '100vh',
    justifyContent: 'space-between',
    width: 250,
  },
  menuContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
  li: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: SPACING * 2,
    position: 'relative',
    '&:hover': {
      color: colors.LIGHT_BLUE,
    }
  },
  liSelected: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: SPACING * 2,
    position: 'relative',
    backgroundColor: colors.STITCH_BLACK,
    fontWeight: 'bold',
    borderLeft: `.25em solid ${colors.YELLOW}`,
    boxSizing: 'border-box',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSelected: {
    marginRight: SPACING * 1,
    marginLeft: '-.25em',
    strokeWidth: 2,
  },
  icon: {
    marginRight: SPACING * 1,
    strokeWidth: 2,
  },
  menuListsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logo: {
    height: 54,
    width: 100
  },
  logout: {
    cursor: 'pointer'
  }
}))

export default Nav
