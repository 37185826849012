import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import { Membership, MembershipParams } from '../types'
import axios from '../utils/axios'
import axiosLib from 'axios'
import { toast } from 'react-toastify'
import stitchAxios from '../utils/axios'
import { TableState } from '../components/Table'

type MembershipObject = Record<string, Membership>

export type MembershipErrorMessage = string;
interface Store extends TableState {
  memberships: MembershipObject;
  getMemberships: () => void;
  getSimpleMembership: (id: string) => void;
  createMembership: (membership: MembershipParams) => Promise<Membership | MembershipErrorMessage>;
  getSubscriptionsForMembership: (membershipId: string) => Promise<void>;
  updateMembership: (membership: Membership) => Promise<void>;
  rehydrated: boolean;
  setRehydrated: (isRehydrated: boolean) => void;
}

const tableState: TableState = {
  searchTerm: '',
  indexPageNumber: 0,
  indexRowsPerPage: 10,
  sortDirection: 'asc'
}

const createFunction = (set: Function, _: Function): Store => ({
  memberships: {},
  getMemberships: async () => {
    const response = await axios.get('/memberships')
    const membershipsArray = response.data
    const memberships = {} as MembershipObject

    membershipsArray.forEach((membership: Membership) => {
      memberships[membership.id] = membership
    })
    set((state: Store) => ({ ...state, memberships }))
  },
  getSimpleMembership: async (membershipId: string) => {
    const res = await axios.get(`/memberships/${membershipId}`)
    set((state: Store) => ({ ...state, memberships: { ...state.memberships, [membershipId]: res.data }, }))
  },
  createMembership: async (membership: MembershipParams): Promise<Membership | MembershipErrorMessage> => {
    try {
      const resp = await stitchAxios.post(`/memberships`, {
        ...membership
      })

      set((state: Store) => ({ ...state, memberships: { ...state.memberships, [resp.data.id]: resp.data }, }))
      return resp.data as Membership
    } catch (e) {
      if (axiosLib.isAxiosError(e)) {
        return (e.response && e.response.data.message) || 'Could not create membership.'
      }
      throw e
    }
  },
  getSubscriptionsForMembership: async (membershipId: string) => {
    const res = await axios.get(`/memberships/${membershipId}/subscriptions`)
    set((state: Store) => ({ ...state, memberships: { ...state.memberships, [membershipId]: res.data }, }))
  },
  updateMembership: async (membership: Membership): Promise<void> => {

  },
  rehydrated: false,
  setRehydrated: (isRehydrated: boolean) => set({ rehydrated: isRehydrated }),
  ...tableState,
})

export const useMembershipsStore = create(devtools(persist(createFunction, {
  name: 'stitchMemberships',
  partialize: (state: Store) => ({ memberships: state.memberships }),
  onRehydrateStorage: (_: Store) => {
    return ((state?: Store | undefined, error?: unknown): void => {
      if (!state) {
        throw error
      }
      state.setRehydrated(true)
    })
  }
})))

export default useMembershipsStore